.vim-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /*background-color: #1e1e1e; !* Dark background *!*/
  padding: 20px;
  box-sizing: border-box;
}

.vim-box {
  position: relative;
  background-color: #2e2e2e; /* Slightly lighter dark background */
  color: #d4d4d4; /* Light text color */
  font-family: 'Courier New', Courier, monospace; /* Monospace font */
  padding: 20px;
  border: 1px solid #4e4e4e; /* Border similar to Vim editor */
  border-radius: 5px;
  max-width: 800px;
  width: 100%;
  white-space: pre-wrap; /* Preserve white space and line breaks */
  overflow-x: auto; /* Add horizontal scroll if needed */
}

.copy-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #4e4e4e;
  color: #d4d4d4;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.9em;
}

.copy-button:hover {
  background-color: #6e6e6e;
}